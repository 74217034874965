import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ICONS } from '@app/open-insurance/shared/constants/icons';

@Component({
  selector: 'app-opin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class OpinHeaderComponent {
  constructor(private location: Location) {}

  ICONS = ICONS;

  @Input() title: string;

  goBackPage() {
    this.location.back();
  }
}
