<main>
  <mat-dialog-actions align="end">
    <button type="button" mat-dialog-close class="close-button-modal">
      <app-opin-icon icon="CLOSE"></app-opin-icon>
    </button>
  </mat-dialog-actions>

  <mat-dialog-content>
    <app-opin-label
      text="{{ details.title }}"
      [isTitle]="true"
      [fontsize]="20"
    ></app-opin-label>

    <app-opin-label
      text="Entenda quais dados são compartilhados:"
    ></app-opin-label>

    <section *ngFor="let item of details.permissions">
      <app-opin-label
        text="{{ item.title }}"
        [isTitle]="true"
        [fontsize]="18"
      ></app-opin-label>
      <ul>
        <li *ngFor="let info of item.details">{{ info }}</li>
      </ul>
    </section>
  </mat-dialog-content>

  <app-opin-button text="Ok, entendi" mat-dialog-close></app-opin-button>
</main>
