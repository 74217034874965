<aside>
  <header>
    <h1>Cancelar o compartilhamento de dados?</h1>
  </header>

  <p>Esta ação não poderá ser desfeita.</p>

  <footer>
    <app-opin-button
      text="Confirmar cancelamento"
      (onClick)="handleRevokeSharing()"
    ></app-opin-button>

    <app-opin-button
      mat-dialog-close
      text="Fechar"
      variant="outlined"
    ></app-opin-button>
  </footer>
</aside>
