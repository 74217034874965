import { Component, EventEmitter, Input, Output } from '@angular/core';

import { HandleToggleParams } from './toggle-slider.model';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-opin-toggle-slider',
  templateUrl: './toggle-slider.component.html',
  styleUrls: ['./toggle-slider.component.scss']
})
export class OpinToggleSliderComponent {
  @Input() isChecked: boolean;
  @Input() name: string;

  @Output() onToggle = new EventEmitter<HandleToggleParams>();

  handleToggle({ checked }: MatSlideToggleChange): void {
    this.onToggle.emit({
      isChecked: checked,
      name: this.name
    });
  }
}
