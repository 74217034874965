import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-opin-text-with-bold-letters',
  templateUrl: './text-with-bold-letters.component.html',
  styleUrls: ['./text-with-bold-letters.component.scss']
})
export class OpinTextWithBoldLettersComponent implements OnInit {
  @Input() text: string;
  @Input() boldWords: string[] = [];

  formattedText: string;

  ngOnInit(): void {
    this.formattedText = this.mountTextWithCorrectBoldTagPositions();
  }

  mountTextWithCorrectBoldTagPositions(): string {
    if (!this.text || !this.boldWords) {
      return this.text;
    }

    let formattedText = this.text;
    this.boldWords.forEach((boldWord) => {
      const escapedBoldWord = boldWord.replace(
        /[-\/\\^$*+?.()|[\]{}]/g,
        '\\$&'
      );
      const regex = new RegExp(
        `(^|\\b|\\s)(${escapedBoldWord})(\\b|$|\\s)`,
        'gu'
      );
      formattedText = formattedText.replace(
        regex,
        (_, before, word, after) => `${before}<b>${word}</b>${after}`
      );
    });

    return formattedText;
  }
}
