import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints
} from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PERMISSION_LABEL } from '@app/open-insurance/modules/consents/services/opin-consent/opin-consent.enum';
import {
  ICONS,
  OpenInsuranceIcons
} from '@app/open-insurance/shared/constants/icons';
import { DialogDetailsComponent } from '../dialog-details/dialog-details.component';
import { DialogDetail } from '../dialog-details/dialog-details.model';
import { CardListInformationItem } from './card-list-information.model';

@Component({
  selector: 'app-opin-card-list-information-container',
  templateUrl: './card-list-information.component.html',
  styleUrls: ['./card-list-information.component.scss']
})
export class OpinCardListInformationComponent implements OnInit, OnDestroy {
  private dialogSizeSubscription: Subscription;
  private static readonly DEFAULT_DIALOG_SIZE = {
    width: '50%',
    height: '80%',
    maxWidth: '100vw',
    maxHeight: '100vh'
  };
  private static readonly SMALL_DIALOG_SIZE = { width: '100%', height: '100%' };

  @Input() title: string;
  @Input() redirectTo: string;
  @Input() redirectText: string;
  @Input() showDetailsButton = false;
  @Input() showItemsDetailsButton = false;
  @Input() redirectIcon: OpenInsuranceIcons;
  @Input() items: CardListInformationItem[];
  @Input() stateOptions: any = {};
  isExtraSmall$: Observable<BreakpointState>;

  readonly ICONS: Record<OpenInsuranceIcons, string> = ICONS;

  constructor(
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isExtraSmall$ = this.breakpointObserver.observe(Breakpoints.XSmall);
  }

  private manageDialogSizeBasedOnScreenSize(
    dialogRef: MatDialogRef<DialogDetailsComponent>
  ): void {
    this.dialogSizeSubscription = this.isExtraSmall$.subscribe(
      ({ matches }) => {
        const dialogSize = matches
          ? OpinCardListInformationComponent.SMALL_DIALOG_SIZE
          : OpinCardListInformationComponent.DEFAULT_DIALOG_SIZE;

        dialogRef.updateSize(dialogSize.width, dialogSize.height);
      }
    );
  }

  private unsubscribeOnDialogClose(
    dialogRef: MatDialogRef<DialogDetailsComponent>
  ): void {
    dialogRef.afterClosed().subscribe({
      next: () => this.dialogSizeSubscription?.unsubscribe(),
      error: (err) => console.error(err)
    });
  }

  openDialog(details: DialogDetail): void {
    const dialogConfig = {
      ...OpinCardListInformationComponent.DEFAULT_DIALOG_SIZE,
      data: details
    };
    const dialogRef = this.dialog.open(DialogDetailsComponent, dialogConfig);

    this.manageDialogSizeBasedOnScreenSize(dialogRef);
    this.unsubscribeOnDialogClose(dialogRef);
  }

  getWordsIntoOneSentence(words: string[]): string {
    if (!words.length) return '';
    const labels = words
      .map((w) => PERMISSION_LABEL[w])
      .filter((label) => !!label);

    const lastWord = labels.pop();
    const formattedWords = labels.length
      ? `${labels.join(', ')} e ${lastWord}.`
      : `${lastWord}.`;
    labels.push(lastWord);

    return formattedWords;
  }

  ngOnInit(): void {
    this.isExtraSmall$ = this.breakpointObserver.observe(Breakpoints.XSmall);
  }

  ngOnDestroy(): void {
    this.dialogSizeSubscription?.unsubscribe();
  }
}
