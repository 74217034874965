import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICONS } from '@app/open-insurance/shared/constants/icons';
import { DialogDetail } from './dialog-details.model';

@Component({
  selector: 'app-dialog-details',
  templateUrl: './dialog-details.component.html',
  styleUrls: ['./dialog-details.component.scss']
})
export class DialogDetailsComponent {
  ICONS = ICONS;

  constructor(@Inject(MAT_DIALOG_DATA) public details: DialogDetail) {}
}
