export enum ICONS {
  EDIT = 'edit',
  CLOSE = 'close',
  TRASH = 'delete',
  CLOCK = 'access_time',
  PERSON = 'account_box',
  INFO = 'info_outlined',
  SWAP_VERT = 'swap_vert',
  QUESTION = 'help_outline',
  CALENDAR = 'calendar_month',
  OPEN_IN_NEW = 'open_in_new',
  RECEIPT = 'receipt_outlined',
  CHECK_CIRCLE = 'check_circle',
  CHEVRON_LEFT = 'chevron_left',
  DOCUMENT_PAPER = 'description',
  CHEVRON_RIGHT = 'chevron_right',
  REQUEST_QUOTE = 'request_quote',
  DO_NOT_DISTURB_ON = 'do_not_disturb_on',
  KEYBOARD_ARROW_DOWN = 'keyboard_arrow_down',
  KEYBOARD_ARROW_RIGHT = 'keyboard_arrow_right',
  SEARCH = 'search',
  FAVORITE_BORDER = 'favorite_border',
  ADMIN_PANEL_SETTINGS = 'admin_panel_settings'
}

export type OpenInsuranceIcons = keyof typeof ICONS;
