import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { OpenInsuranceIcons } from '@app/open-insurance/shared/constants/icons';

export type ButtonWithIconVariant =
  | 'ghost'
  | 'primary'
  | 'outlined'
  | 'secundary';

@Component({
  selector: 'app-opin-button-with-icon',
  templateUrl: './button-with-icon.component.html',
  styleUrls: ['./button-with-icon.component.scss']
})
export class OpinButtonWithIconComponent implements OnInit {
  @Input() text: string;
  @Input() disabled = false;
  @Input() isFloating = false;
  @Input() variant: ButtonWithIconVariant = 'primary';

  @Input() leftIcon?: OpenInsuranceIcons;
  @Input() rightIcon?: OpenInsuranceIcons;
  @Input() rightIconColor?: string;

  @Output() onClick = new EventEmitter();

  handleClick() {
    this.onClick.emit();
  }

  getButtonClasses(): Record<string, boolean> {
    return {
      floating: this.isFloating,
      ghost: this.variant === 'ghost',
      primary: this.variant === 'primary',
      outlined: this.variant === 'outlined',
      secundary: this.variant === 'secundary'
    };
  }

  private checkIfShouldRenderButton() {
    if (!this.leftIcon && !this.rightIcon) {
      throw new Error(
        'app-opin-button-with-icon must have at least "left" or "right" icon.'
      );
    }
  }

  ngOnInit(): void {
    this.checkIfShouldRenderButton();
  }
}
