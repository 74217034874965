<section class="opin-summary-card">
  <header>
    <app-opin-label
      text="{{ title }}"
      [isTitle]="true"
      [fontsize]="18"
    ></app-opin-label>

    <div *ngIf="redirectText">
      <app-opin-text-redirect
        [text]="redirectText"
        [icon]="redirectIcon"
        [pathRedirect]="redirectTo"
      >
      </app-opin-text-redirect>
    </div>
  </header>

  <aside *ngFor="let item of items; last as isLast">
    <section>
      <app-opin-icon [icon]="item.icon" [size]="40"></app-opin-icon>

      <footer>
        <div>
          <app-opin-label
            [text]="item.textPrimary"
            [isTitle]="true"
          ></app-opin-label>
          <app-opin-label
            [text]="item.textSecondary"
            [fontsize]="15"
          ></app-opin-label>
        </div>

        <app-opin-label
          *ngIf="item.extraInfo"
          [text]="getWordsIntoOneSentence(item.extraInfo)"
          [fontsize]="13"
        ></app-opin-label>

        <app-opin-button-with-icon
          *ngIf="showItemsDetailsButton"
          class="detail-button"
          [text]="'Ver detalhes'"
          [variant]="'ghost'"
          rightIcon="CHEVRON_RIGHT"
          (onClick)="openDialog(item.details)"
        ></app-opin-button-with-icon>
      </footer>
    </section>
    <app-opin-divider *ngIf="!isLast"></app-opin-divider>
  </aside>
</section>
