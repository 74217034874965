<section class="open-insurance-consent-card">
  <header class="open-insurance-consent-card-header">
    <section>
      <div>
        <app-opin-icon [icon]="icon" [size]="40"></app-opin-icon>
      </div>
      <div>
        <p>{{ title }}</p>
        <small>{{ subtitle }}</small>
      </div>
    </section>
    <section *ngIf="canToggle">
      <app-opin-toggle-slider
        (onToggle)="handleToggleSlider($event)"
        [name]="name"
        [isChecked]="status"
      ></app-opin-toggle-slider>
    </section>
  </header>

  <section>
    <app-opin-label
      [text]="friendlyChoices"
      [fontsize]="13"
      class="mb5"
    ></app-opin-label>
  </section>

  <footer>
    <a (click)="openDialog()" class="open-dialog-link">
      <app-opin-text text="Ver detalhes"></app-opin-text>
      <app-opin-icon icon="CHEVRON_RIGHT" [size]="24"></app-opin-icon>
    </a>
  </footer>
</section>
