<aside [ngClass]="getContainerClassType()">
  <header>
    <app-opin-icon icon="INFO" [size]="24"></app-opin-icon>
  </header>

  <footer>
    <app-opin-label [text]="text" [isTitle]="true" id="title"></app-opin-label>

    <app-opin-label
      *ngIf="description"
      [text]="description"
      id="description"
    ></app-opin-label>

    <app-opin-button
      id="cta-button"
      size="fit"
      variant="ghost"
      *ngIf="button"
      [text]="button.text"
      (onClick)="button.onClick()"
    ></app-opin-button>
  </footer>
</aside>
