import { CONSENT_STATUS } from '@app/open-insurance/modules/consents/services/opin-consent/opin-consent.model';

export const SharingStatus = {
  [CONSENT_STATUS.AUTHORISED]: 'Ativo até ',
  [CONSENT_STATUS.EXPIRED]: 'Vencido em ',
  [CONSENT_STATUS.CLOSED]: 'Encerrado em ',
  [CONSENT_STATUS.REJECTED]: 'Rejeitado em ',
  [CONSENT_STATUS.AWAITING_AUTHORISATION]: 'Esperando autorização desde ',
  [CONSENT_STATUS.REVOKED]: 'Revogado em '
};
