import { Component, Input, OnInit } from '@angular/core';
import { OpinIconComponent } from '../../atoms/icon/icon.component';

@Component({
  selector: 'app-opin-card-simple-information-container',
  templateUrl: './card-simple-information.component.html',
  styleUrls: ['./card-simple-information.component.scss']
})
export class OpinCardSimpleInformationContainerComponent implements OnInit {
  @Input() src: string;
  @Input() alt: string;
  @Input() title: string;
  @Input() extraInfo: string;
  @Input() textRedirect: string;
  @Input() textPrimary: string;
  @Input() pathRedirect: string;
  @Input() textSecondary: string;
  @Input() icon: OpinIconComponent['icon'];
  @Input() redirectIcon: OpinIconComponent['icon'] = 'EDIT';

  imageSource =
    '/assets/open-insurance/icons/sharing-card-placeholder-image.png';

  private async checkImageExists(url: string): Promise<void> {
    new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    }).then((imageExists: boolean) => {
      if (imageExists) this.imageSource = url;
    });
  }

  ngOnInit(): void {
    this.checkImageExists(this.src);
  }
}
