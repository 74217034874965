<main>
  <mat-dialog-actions align="end">
    <button type="button" mat-dialog-close class="close-button-modal">
      <app-opin-icon icon="CLOSE" [size]="40"></app-opin-icon>
    </button>
  </mat-dialog-actions>

  <mat-dialog-content>
    <app-opin-label
      text="Principais dúvidas sobre o Open Insurance"
      [isTitle]="true"
      [fontsize]="22"
    ></app-opin-label>

    <mat-accordion multi>
      <mat-expansion-panel *ngFor="let item of items">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ item.question }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p>{{ item.answer }}</p>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
</main>
