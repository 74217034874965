import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OpinIconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-opin-text-redirect',
  templateUrl: './text-redirect.component.html',
  styleUrls: ['./text-redirect.component.scss']
})
export class OpinTextRedirectComponent {
  @Input() text: string;
  @Input() pathRedirect: string;
  @Input() icon: OpinIconComponent['icon'];

  constructor(private readonly router: Router) {}

  handleClick() {
    this.router.navigateByUrl(this.pathRedirect);
  }
}
