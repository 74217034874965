import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  OPEN_INSURANCE_ROUTE,
  SHARED_CONSENTS_ROUTES
} from '@app/open-insurance/shared/constants/routes';
import { ButtonVariant } from '../../atoms/button/button.component';

@Component({
  selector: 'app-opin-sharing-header',
  templateUrl: './sharing-header.component.html',
  styleUrls: ['./sharing-header.component.scss']
})
export class OpinSharingHeaderComponent {
  SHARED_CONSENTS_ROUTES = SHARED_CONSENTS_ROUTES;

  constructor(
    private readonly router: Router,
    private readonly location: Location
  ) {}

  private currentLocationPath = this.location.path();

  getButtonType(path: 'enviados' | 'recebidos'): ButtonVariant {
    return this.currentLocationPath.includes(path) ? 'primary' : 'outlined';
  }

  handleRedirectTo(
    page:
      | typeof SHARED_CONSENTS_ROUTES.SENT
      | typeof SHARED_CONSENTS_ROUTES.RECEIVED
  ) {
    this.router.navigateByUrl(`${OPEN_INSURANCE_ROUTE}/${page}`);
  }
}
