import { Component, Input } from '@angular/core';
import { FooterButton } from './buttons-footer.model';

@Component({
  selector: 'app-opin-buttons-footer',
  templateUrl: './buttons-footer.component.html',
  styleUrls: ['./buttons-footer.component.scss']
})
export class OpinButtonsFooterComponent {
  @Input() buttons: [FooterButton, FooterButton];
}
