import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  ICONS,
  OpenInsuranceIcons
} from '@app/open-insurance/shared/constants/icons';

import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HandleToggleParams } from '../../atoms/toggle-slider/toggle-slider.model';
import { DialogDetailsComponent } from '../dialog-details/dialog-details.component';
import { Choice } from '@app/open-insurance/open-insurance.model';
import { DialogDetail } from '../dialog-details/dialog-details.model';

@Component({
  selector: 'app-opin-consent-card',
  templateUrl: './consent-card.component.html',
  styleUrls: ['./consent-card.component.scss']
})
export class OpinConsentCardComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  isExtraSmall$: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  ICONS = ICONS;

  @Input() name: string;
  @Input() title: string;
  @Input() status: boolean;
  @Input() subtitle: string;
  @Input() icon: OpenInsuranceIcons;
  @Input() canToggle = true;
  @Input() choices: Choice[];
  @Input() details: DialogDetail;

  @Output() onToggle = new EventEmitter<HandleToggleParams>();

  friendlyChoices: string;

  ngOnInit() {
    this.friendlyChoices = this.getFriendlyChoices(this.choices);
  }

  handleToggleSlider({ isChecked }: HandleToggleParams) {
    this.onToggle.emit({
      isChecked,
      name: this.name
    });
  }

  getFriendlyChoices(choices: Choice[]) {
    const choicesToPermissionList = choices.map((choice) => choice.label);

    if (!choicesToPermissionList.length) return '';

    const labels = choicesToPermissionList.filter((label) => !!label);

    const lastWord = labels.pop();

    const formattedWords = labels.length
      ? `${labels.join(', ')} e ${lastWord}.`
      : `${lastWord}.`;

    return formattedWords;
  }

  openDialog(): void {
    const signInDialogRef = this.dialog.open(DialogDetailsComponent, {
      width: '50%',
      height: '80%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: this.details
    });

    const smallDialogSubscription = this.isExtraSmall$.subscribe((result) => {
      if (result.matches) {
        signInDialogRef.updateSize('100%', '100%');
      } else {
        signInDialogRef.updateSize('50%', '80%');
      }
    });

    signInDialogRef
      .afterClosed()
      .pipe(
        tap(() => {
          smallDialogSubscription.unsubscribe();
        })
      )
      .subscribe();
  }
}
