import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-opin-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class OpinTextComponent {
  @Input() text: string;
  @Input() bold: boolean;
}
