import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-opin-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class OpinLabelComponent {
  @Input() text: string;
  @Input() fontsize = 16;
  @Input() isTitle = false;
  @Input() isExtraInfo = false;
}
