import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ICONS,
  OpenInsuranceIcons
} from '@app/open-insurance/shared/constants/icons';

export type ButtonVariant = 'ghost' | 'primary' | 'outlined' | 'white';

@Component({
  selector: 'app-opin-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class OpinButtonComponent {
  ICONS: Record<OpenInsuranceIcons, string> = ICONS;

  @Input() text: string;
  @Input() disabled = false;
  @Input() variant: ButtonVariant = 'primary';
  @Input() size: 'fit' | 'full' = 'full';
  @Input() loading = false;
  @Input() colorIcon: string;
  @Input() icon: OpenInsuranceIcons;
  @Input() addAlignButtonWrapperClass = false;

  @Output() onClick = new EventEmitter();

  handleClick() {
    this.onClick.emit();
  }

  getButtonClasses(): Record<string, boolean> {
    return {
      fit: this.size === 'fit',
      ghost: this.variant === 'ghost',
      primary: this.variant === 'primary',
      outlined: this.variant === 'outlined',
      white: this.variant === 'white'
    };
  }
}
