<header>
  <button (click)="goBackPage()" id="back-button">
    <app-opin-icon icon="CHEVRON_LEFT"></app-opin-icon>
  </button>

  <app-opin-text [text]="title" [bold]="true"></app-opin-text>

  <button id="help-button">
    <app-opin-icon icon="QUESTION"></app-opin-icon>
  </button>
</header>
