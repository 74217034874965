import { Component, Input } from '@angular/core';
import { Required } from '@app/shared/decorators/required';
import { OpinCardButton, OpinCardIcon, OpinCardLink } from './opin-card.model';

@Component({
  selector: 'app-opin-card',
  templateUrl: './opin-card.component.html',
  styleUrls: ['./opin-card.component.scss']
})
export class OpinCardComponent {
  @Input()
  @Required
  button: OpinCardButton;

  @Input()
  @Required
  title: string;

  @Input()
  @Required
  icon: OpinCardIcon;

  @Input()
  link?: OpinCardLink;

  @Input()
  id?: string;
}
