<header>
  <app-opin-button
    id="sent-button"
    text="Enviados"
    [variant]="getButtonType('enviados')"
    (onClick)="handleRedirectTo(SHARED_CONSENTS_ROUTES.SENT)"
  ></app-opin-button>

  <app-opin-button
    id="received-button"
    text="Recebidos"
    [variant]="getButtonType('recebidos')"
    (onClick)="handleRedirectTo(SHARED_CONSENTS_ROUTES.RECEIVED)"
  ></app-opin-button>
</header>
