import { Component, Input, OnInit } from '@angular/core';
import {
  CONSENT_STATUS,
  TransmissionSharingDTO
} from '@app/open-insurance/modules/consents/services/opin-consent/opin-consent.model';

@Component({
  selector: 'app-opin-sharing-card-list',
  templateUrl: './sharing-card-list.component.html',
  styleUrls: ['./sharing-card-list.component.scss']
})
export class OpinSharingCardListComponent implements OnInit {
  @Input() sharings: TransmissionSharingDTO[];
  @Input() variant: 'sent' | 'received';

  sortedSharings: OpinSharingCardListComponent['sharings'];
  private AUTHORISED_STATUS = CONSENT_STATUS.AUTHORISED;

  private getActiveSharings(): TransmissionSharingDTO[] {
    return this.sharings.filter(
      (sharing) => sharing.status === this.AUTHORISED_STATUS
    );
  }

  private getInactiveSharings(): TransmissionSharingDTO[] {
    return this.sharings.filter(
      (sharing) => sharing.status !== this.AUTHORISED_STATUS
    );
  }

  private sortActiveSharingsByExpirationDate() {
    const activeSharings = this.getActiveSharings();

    const activeSharingsSortedByExpirationDate = activeSharings.sort(
      (sharingOne, sharingTwo) => {
        const sharingOneExpirationDate = new Date(sharingOne.expirationDate);
        const sharingTwoExpirationDate = new Date(sharingTwo.expirationDate);

        const crescentSharingWithLatestExpirationDate =
          sharingOneExpirationDate.getTime() -
          sharingTwoExpirationDate.getTime();

        return crescentSharingWithLatestExpirationDate;
      }
    );

    return activeSharingsSortedByExpirationDate;
  }

  private makeSharings() {
    const sortedActiveSharings = this.sortActiveSharingsByExpirationDate();
    const sharingsWithoutActiveStatus = this.getInactiveSharings();

    this.sortedSharings = [
      ...sortedActiveSharings,
      ...sharingsWithoutActiveStatus
    ];
  }

  ngOnInit(): void {
    this.makeSharings();
  }
}
