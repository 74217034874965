<aside [ngClass]="{ active: data.status === 'AUTHORISED' }">
  <section>
    <header id="sharing-resume-header" *ngIf="variant === 'resume'">
      <b>Compartilhamento</b>

      <app-opin-button-with-icon
        *ngIf="data.status.toLowerCase() === 'authorised' && shouldRevoke"
        text="Cancelar"
        variant="ghost"
        rightIcon="TRASH"
        (onClick)="handleRevokeSharing()"
      ></app-opin-button-with-icon>
    </header>

    <header class="sharing-card-header">
      <section>
        <img [src]="imageSource" [alt]="altImageMessage" />

        <app-opin-label
          [text]="data.participantName"
          [isTitle]="true"
          [fontsize]="16"
        ></app-opin-label>
      </section>

      <a
        [href]="showSharingResumeURL"
        *ngIf="!shouldRevoke && variant !== 'resume'"
      >
        <app-opin-icon
          icon="CHEVRON_RIGHT"
          [size]="22"
          *ngIf="detalheHabilitado"
        ></app-opin-icon>
      </a>
    </header>

    <app-opin-divider></app-opin-divider>

    <footer>
      <app-opin-icon
        [size]="26"
        id="active"
        icon="CHECK_CIRCLE"
        *ngIf="data.status.toLowerCase() === 'authorised'"
      ></app-opin-icon>

      <app-opin-icon
        [size]="26"
        id="inactive"
        icon="DO_NOT_DISTURB_ON"
        *ngIf="data.status.toLowerCase() !== 'authorised'"
      ></app-opin-icon>

      <app-opin-label [text]="expirationDate" [fontsize]="14"></app-opin-label>
    </footer>
  </section>
</aside>
