import { Component, Input } from '@angular/core';
import { Alert } from './alert.model';

@Component({
  selector: 'app-opin-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class OpinAlertComponent implements Alert {
  @Input() text: Alert['text'];
  @Input() button: Alert['button'];
  @Input() variant: Alert['variant'];
  @Input() description: Alert['description'];

  private isVariantSelected(variant: typeof this.variant) {
    return this.variant === variant;
  }

  getContainerClassType(): Record<typeof this.variant, boolean> {
    return {
      info: this.isVariantSelected('info'),
      error: this.isVariantSelected('error'),
      warning: this.isVariantSelected('warning')
    };
  }
}
