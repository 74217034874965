<mat-card>
  <header>
    <app-opin-label
      [text]="title"
      [isTitle]="true"
      [fontsize]="18"
    ></app-opin-label>

    <app-opin-text-redirect
      *ngIf="textRedirect"
      [text]="textRedirect"
      [icon]="redirectIcon"
      class="text-redirect"
      [pathRedirect]="pathRedirect"
    >
    </app-opin-text-redirect>
  </header>

  <footer>
    <img *ngIf="src" [src]="imageSource" [alt]="alt" />
    <app-opin-icon [size]="40" *ngIf="icon" [icon]="icon"></app-opin-icon>

    <section>
      <app-opin-label
        [text]="textPrimary"
        [isTitle]="true"
        [fontsize]="14"
      ></app-opin-label>

      <app-opin-label
        *ngIf="textSecondary"
        [text]="textSecondary"
        [fontsize]="13"
        class="mb5"
      ></app-opin-label>

      <app-opin-label
        [isExtraInfo]="true"
        [text]="extraInfo"
        [fontsize]="11"
      ></app-opin-label>
    </section>
  </footer>
</mat-card>
