import {
  CONSENT_PERMISSION,
  LIFE_PENSION_PLAN_PERMISSION,
  PENSION_PLAN_PERMISSION,
  PERSONAL_DATA_PERMISSION,
  PERSON_PERMISSION
} from './opin-consent.enum';

export interface Tpp {
  name: string;
  logoUrl: string;
}

export class PermissionInfo {
  title: string;
  details: string[];
}

export class PermissionDetail {
  title: string;
  permissions: PermissionInfo[];
}

export class Participant {
  name: string;
  logoUrl: string;
}

export class PersonalData {
  name: string;
  cpf: string;
  status: boolean;
  permissions: (keyof typeof PERSONAL_DATA_PERMISSION)[];
  details: PermissionDetail;
}

export type ResourcePermission =
  | keyof typeof PERSONAL_DATA_PERMISSION
  | keyof typeof LIFE_PENSION_PLAN_PERMISSION
  | keyof typeof PENSION_PLAN_PERMISSION
  | keyof typeof PERSON_PERMISSION;

export class Resource {
  id: string;
  label: string;
  status: boolean;
  certificateId: string;
  details: PermissionDetail;
  permissions: ResourcePermission[];
}

export enum CONSENT_STATUS {
  'REJECTED' = 'REJECTED',
  'AUTHORISED' = 'AUTHORISED',
  'EXPIRED' = 'EXPIRED',
  'AWAITING_AUTHORISATION' = 'AWAITING_AUTHORISATION',
  'CLOSED' = 'CLOSED',
  'REVOKED' = 'REVOKED'
}

export enum SHARING_STATUS_LABEL {
  AUTHORISED = 'Ativo até ',
  EXPIRED = 'Vencido em ',
  CLOSED = 'Encerrado em ',
  REJECTED = 'Rejeitado em ',
  AWAITING_AUTHORISATION = 'Esperando autorização desde ',
  REVOKED = 'Revogado em '
}

export class TransmissionSharingDTO {
  id: string;
  participantName: string;
  thumbnail: string;
  status: keyof typeof SHARING_STATUS_LABEL;
  expirationDate: Date;
}

export class ConsentDTO {
  id: string;
  statusDate: Date;
  expirationDate: Date;
  solicitationDate: Date;
  participant: Participant;
  personalData: PersonalData;
  personResources: Resource[];
  lifePensionResources: Resource[];
  pensionPlanResources: Resource[];
  status: keyof typeof CONSENT_STATUS;
}

export interface Redirect {
  isHandOff: boolean;
  redirectTo: string;
}

export interface ErrorConsent {
  redirect: Redirect;
  type: string;
  message: string;
  tpp: Tpp;
}

export interface CompleteConsent {
  tpp: Tpp;
  redirect: Redirect;
}

export interface GetConfirmedOrRefusedConsentCommandPayload {
  selectedResourcesIDs: string[];
}

export interface CompleteConsentResourcePayloadDTO {
  id: string;
  value: string;
  permissions: ResourcePermission[];
}

export interface CompleteConsentPayloadDTO {
  commandId: string;
  consentId: string;
  createdOn: Date;
  expiredOn: Date;
  tppName: string;
  resources: CompleteConsentResourcePayloadDTO[];
}

export type ConsentPermissionKey = keyof typeof CONSENT_PERMISSION;
