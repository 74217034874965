import { Component, Input, OnInit } from '@angular/core';
import {
  ICONS,
  OpenInsuranceIcons
} from '@app/open-insurance/shared/constants/icons';
@Component({
  selector: 'app-opin-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class OpinIconComponent implements OnInit {
  ICONS: Record<OpenInsuranceIcons, string> = ICONS;
  iconExists: boolean;

  @Input() size = 24;
  @Input() color: string;
  @Input() icon: OpenInsuranceIcons;

  ngOnInit(): void {
    try {
      this.iconExists = !!this.ICONS[this.icon];
    } catch {
      console.error(`Icon ${this.icon} does not exists in OpenInsuranceIcons`);

      this.iconExists = false;
    }
  }
}
