<button
  mat-button
  (click)="handleClick()"
  [disabled]="!!disabled"
  [ngClass]="getButtonClasses()"
>
  <app-opin-icon
    [size]="20"
    *ngIf="leftIcon"
    [icon]="leftIcon"
    id="left-icon"
  ></app-opin-icon>

  {{ text }}

  <app-opin-icon
    [size]="20"
    *ngIf="rightIcon"
    [icon]="rightIcon"
    id="right-icon"
    [color]="rightIconColor"
  ></app-opin-icon>
</button>
