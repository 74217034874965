import { HttpResponseBase } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConsentManagementService } from '@app/open-insurance/modules/consent-management/consent-management.service';
import { ICONS } from '@app/open-insurance/shared/constants/icons';
import {
  OPEN_INSURANCE_ROUTE,
  SHARED_CONSENTS_ROUTES
} from '@app/open-insurance/shared/constants/routes';
import { Subscription, throwError } from 'rxjs';
import { RevokeSharingDialogDetail } from './revoke-sharing-dialog.model';

@Component({
  selector: 'app-revoke-sharing-dialog',
  templateUrl: './revoke-sharing-dialog.component.html',
  styleUrls: ['./revoke-sharing-dialog.component.scss']
})
export class RevokeDialogComponent implements OnDestroy {
  ICONS = ICONS;

  private readonly RevokeSharingDialogComponentSubscriptions: Subscription[] =
    [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly consentManagementService: ConsentManagementService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public readonly details: RevokeSharingDialogDetail
  ) {}

  async handleRevokeSharing() {
    try {
      if (this.details.type === 'transmission') {
        await this.consentManagementService.revokeTransmissionConsentByID(
          this.details.sharingConsentID
        );

        this.router.navigateByUrl(
          `${OPEN_INSURANCE_ROUTE + '/' + SHARED_CONSENTS_ROUTES.SENT}`
        );
      } else {
        await this.consentManagementService.revokeReceptionConsentByID(
          this.details.sharingConsentID
        );

        this.router.navigateByUrl(
          `${OPEN_INSURANCE_ROUTE + '/' + SHARED_CONSENTS_ROUTES.RECEIVED}`
        );
      }
    } catch (error) {
      if (error instanceof HttpResponseBase) {
        if (404 === error.status) {
        }
      } else {
        throwError(error);
      }
    } finally {
      this.dialog.closeAll();
    }
  }

  ngOnDestroy(): void {
    this.RevokeSharingDialogComponentSubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }
}
