import { Component } from '@angular/core';
import { DialogFAQItem } from './dialog-faq.model';

const DIALOG_OPIN_FAQ: DialogFAQItem[] = [
  {
    question: 'O Que é Open Insurance?',
    answer:
      'O Open Insurance, ou sistema de seguros aberto, propicia o compartilhamento padronizado de dados e serviços por meio de APIs (Application Programming Interfaces), que são um conjunto de normas que possibilitam a comunicação entre plataformas através de uma série de padrões e protocolos. Neste caso, será a comunicação entre seguradoras e demais organizações autorizadas a funcionar pela Superintendência de Seguros Privados – SUSEP. No caso de dados de clientes (pessoa física ou jurídica), são estes que decidirão quando e com quem desejam compartilhar os dados do Open Insurance, desde que seja com finalidades específicas e prazos determinados.'
  },
  {
    question: 'Quais são os benefícios do Open Insurance?',
    answer:
      'Com o Open Insurance, espera-se a entrega de produtos e serviços de seguros a consumidores com maior agilidade, conveniência e segurança. Além da possibilidade de integração da prestação de serviços de seguros à jornada digital do consumidor, são fornecidas condições para o surgimento de modelos de negócio que têm o cliente no centro. Esses novos modelos, que podem envolver, por exemplo, comparadores de preços, produtos e serviços, possibilitarão ao consumidor maior visibilidade e controle dos produtos contratados, bem como o acesso a produtos personalizados e a condições mais vantajosas e adequadas às suas necessidades, interesses e objetivos.'
  },
  {
    question:
      'Como será o processo de implementação do Open Insurance? E quais informações poderei compartilhar?',
    answer:
      'Fase 1 Abertura e padronização de dados das seguradoras: as informações sobre canais de atendimento e produtos e serviços devem ser disponibilizadas. Fase 2 Compartilhamento de dados do consumidor: você poderá compartilhar seus dados com as seguradoras de sua preferência. Tudo feito por meio de consentimento, que pode ser revogado a qualquer momento. Fase 3 Serviços à escolha do consumidor: acesso a serviços de seguros sem a necessidade de acessar os canais das seguradoras com as quais você já tem relacionamento.'
  },
  {
    question:
      'É possível fazer o processo de movimentação dos dados por uma outra seguradora diferente da que mantém o meu contrato?',
    answer:
      'Sim. Mediante seu prévio consentimento, será possível iniciar a movimentação a partir de uma seguradora prestadora de serviço de inicialização de movimentação, conforme a regulamentação em vigor.'
  },
  {
    question:
      'Quem poderá autorizar o compartilhamento de dados no escopo do Open Insurance?',
    answer:
      'Poderão autorizar o compartilhamento de dados os clientes (tanto pessoas físicas como jurídicas) de sociedades participantes do Open Insurance com as quais mantêm relacionamento destinado à prestação de serviço de seguros.'
  },
  {
    question: 'Quando poderei compartilhar os dados?',
    answer:
      'A partir da 2ª fase do Open Insurance, as sociedades participantes possibilitam aos clientes solicitarem o compartilhamento de informações cadastrais e transacionais de produtos de seguros.'
  },
  {
    question: 'Com quem eu posso/poderei compartilhar os meus dados?',
    answer:
      'Com seguradoras, sandbox ou demais organizações autorizadas a funcionar pela SUSEP. Para mais informações sobre as sociedades participantes, clique aqui.'
  },
  {
    question: 'Eu estou/estarei automaticamente registrado no Open Insurance?',
    answer:
      'Não. Para compartilhar seus dados no Open Insurance é necessária sua manifestação ativa, na forma de consentimento para finalidades determinadas à seguradora para a qual deseja enviar seus dados. O consentimento terá prazo de validade compatível com essas finalidades, limitado a 12 meses. Esse processo será feito em ambiente digital seguro, inclusive com a identificação do consumidor. Atenção! A 1ª fase do Open Insurance não envolve o compartilhamento de dados pessoais dos clientes, de forma que o consentimento não é exigido nessa etapa.'
  },
  {
    question:
      'Como eu autorizo o compartilhamento dos meus dados pelo Open Insurance?',
    answer:
      'Primeiro, você deverá se identificar e fornecer seu consentimento no canal eletrônico da seguradora que você quer que tenha acesso aos seus dados. Em seguida, você será redirecionado para a seguradora que mantém tais dados, e nesse ambiente poderá autenticar a sua identidade e confirmar o compartilhamento. No último passo, você será redirecionado ao ambiente em que foi feita a solicitação inicial para a recepção de comunicação sobre a efetivação da solicitação de compartilhamento. Somente então seus dados serão compartilhados, observado o prazo de validade do consentimento. Quando for confirmar o compartilhamento, você poderá revisar as informações que deseja compartilhar e verificar se estão corretos. Lembre-se de que todo o processo ocorrerá exclusivamente pelos canais eletrônicos das empresas.'
  },
  {
    question:
      'Será cobrado algum valor do cliente pelo compartilhamento de dados?',
    answer:
      'Não. Não será cobrado nenhum valor do cliente pelo compartilhamento de dados.'
  },
  {
    question:
      'Recebi uma comunicação solicitando o compartilhamento dos meus dados, o que faço?',
    answer:
      'Somente prossiga com o consentimento para compartilhamento de dados se você conhecer a informação que está sendo compartilhada e a sociedade que a receberá, e se tem legítimo interesse no compartilhamento desses dados. Caso desconfie da origem da comunicação recebida, entre em contato com a seguradora que enviou a mensagem.'
  },
  {
    question:
      'Quem pode autorizar o compartilhamento de meus dados no Open Insurance?',
    answer:
      'Só você pode autorizar o compartilhamento dos seus dados no Open Insurance. As seguradoras não poderão transmitir seus dados pessoais a terceiros sem o seu consentimento expresso.'
  },
  {
    question: 'Como eu cancelo o acesso/compartilhamento aos meus dados?',
    answer:
      'Você pode pedir o cancelamento do compartilhamento dos seus dados tanto na seguradora a que deu o consentimento como na seguradora que transmitirá a ela esses dados. Cada uma delas vai indicar a maneira exata para a revogação do consentimento, assim como os canais de atendimento disponíveis. Você sempre poderá pedir a retirada do consentimento pelo mesmo canal de atendimento em que ele foi concedido, se este ainda estiver disponível. Após a sua solicitação, o consentimento será cancelado de forma imediata ou, no caso da iniciação de movimentação, em até um dia.'
  },
  {
    question:
      'Por meio de quais canais posso solicitar o compartilhamento de dados e serviços do Open Insurance?',
    answer:
      'Você só poderá solicitar o compartilhamento de dados e serviços do Open Insurance por meio de canais digitais e acessos móveis das seguradoras ou demais organizações autorizadas a funcionar pela SUSEP como participantes do Open Insurance.'
  },
  {
    question: 'É seguro compartilhar meus dados no Open Insurance?',
    answer:
      'Preservar a segurança em relação aos dados e serviços compartilhados é um dos principais objetivos do Open Insurance. Por isso, as sociedades participantes devem cumprir uma série de requisitos para garantir a autenticidade, a segurança e o sigilo das informações compartilhadas. Estão previstas regras específicas para responsabilização da seguradora e de seus dirigentes, com mecanismos de acompanhamento e controle do processo de compartilhamento e regras específicas de responsabilização da seguradora e de seus dirigentes. Além disso, as sociedades participantes devem observar outras exigências previstas na legislação e regulamentação vigentes para assegurar a segurança e a confiabilidade do processo de compartilhamento, a exemplo das regras relativas à implementação de políticas de segurança cibernética.'
  }
];

@Component({
  selector: 'app-dialog-faq',
  templateUrl: './dialog-faq.component.html',
  styleUrls: ['./dialog-faq.component.scss']
})
export class DialogFAQComponent {
  items = DIALOG_OPIN_FAQ;
}
