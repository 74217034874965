import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransmissionSharingDTO } from '@app/open-insurance/modules/consents/services/opin-consent/opin-consent.model';
import { ICONS } from '@app/open-insurance/shared/constants/icons';
import {
  OPEN_INSURANCE_ROUTE,
  SHARED_CONSENTS_ROUTES
} from '@app/open-insurance/shared/constants/routes';
import { format } from '@app/shared/date-fns';
import { SharingStatus } from './sharing-card.model';

@Component({
  selector: 'app-opin-sharing-card',
  templateUrl: './sharing-card.component.html',
  styleUrls: ['./sharing-card.component.scss']
})
export class OpinSharingCardComponent implements OnInit {
  ICONS = ICONS;
  expirationDate: string;
  altImageMessage: string;
  private _showSharingResumeURL: string;
  imageSource =
    '/assets/open-insurance/icons/sharing-card-placeholder-image.png';

  @Input() data: TransmissionSharingDTO;
  @Input() shouldRevoke = false;
  @Input() variant: 'sent' | 'received' | 'resume';
  @Output() onRevoke = new EventEmitter();
  @Input() detalheHabilitado = true;

  get showSharingResumeURL() {
    return `${OPEN_INSURANCE_ROUTE}/${this._showSharingResumeURL}`;
  }

  private makeShowSharingResumeURL() {
    if (this.variant === 'sent') {
      this._showSharingResumeURL = SHARED_CONSENTS_ROUTES.SENT_RESUME(
        this.data.id
      );
    } else {
      this._showSharingResumeURL = SHARED_CONSENTS_ROUTES.RECEIVED_RESUME(
        this.data.id
      );
    }
  }

  private getDateLastStatus() {
    const { status } = this.data;
    const statusMessage = SharingStatus[status];

    const formattedExpirationDate = format({
      date: new Date(this.data.expirationDate),
      dateFormat: `'${statusMessage}' dd/MM/yyyy`
    });

    return formattedExpirationDate;
  }

  private async checkImageExists(url: string): Promise<void> {
    new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    }).then((imageExists: boolean) => {
      if (imageExists) this.imageSource = url;
    });
  }

  handleRevokeSharing() {
    this.onRevoke.emit();
  }

  ngOnInit(): void {
    this.makeShowSharingResumeURL();
    this.expirationDate = this.getDateLastStatus();
    this.altImageMessage = `Logo da instituição ${this.data.participantName}`;
    this.checkImageExists(this.data.thumbnail);
  }
}
