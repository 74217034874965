<aside [id]="id">
  <header>
    <img [src]="icon.path" [alt]="icon.alt" />
  </header>

  <section>
    <h3 class="title">{{ title }}</h3>

    <footer>
      <app-opin-button
        variant="outlined"
        [text]="button.title"
        (click)="button.click()"
      ></app-opin-button>

      <a *ngIf="link" [href]="link.href" target="_blank"
        >{{ link.text }}
        <app-opin-icon icon="OPEN_IN_NEW" [size]="20"></app-opin-icon>
      </a>
    </footer>
  </section>
</aside>
